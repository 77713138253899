<script>
import GalleryImageForm from "@/views/menu/gallery/gallery-image/galleryImageForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    galleryService: "galleryService",
    fileUploadService: "fileUploadService",
    toastService: "toastService"
  }
})
export default class GalleryImageEdit extends mixins(GalleryImageForm) {
  formId = "edit-gallery-image-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.galleryService.updateGalleryImage(this.model.idGallery, this.model.id, this.model);
      this.$router.push(`/gallery-images/${this.model.idGallery}/list`);
      this.toastService.success(this.translations.success.galleryImage_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.galleries_edit;
  }

  async afterCreate () {
    this.model = await this.galleryService.readGalleryImage(this.$route.params.idParent, this.$route.params.id);
    this.isReady = true;
  }
}
</script>
