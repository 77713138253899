import Component, { mixins } from "vue-class-component";
import EntityForm from "@/mixins/EntityForm";

@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService",
    galleryService: "galleryService"
  },
})
export default class GalleryImageForm extends mixins(EntityForm) {
  model = {};
  gallery = {};
  events = [];

  get parentLanguages() {
    if (!this.gallery.idEvent) {
      return [];
    }
    const idEvent = this.gallery.idEvent;
    const event = this.events.filter(e => e.id == idEvent);
    if (event.length == 0) {
      return [];
    }
    return event[0].languages;
  }

  get elements() {
    return [
      {
        legend: this.translations.labels.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "code",
            label: this.translations.labels.galleryImage_form_code,
            type: "text",
            required: true
          },
          {
            id: "displayOrder",
            label: this.translations.labels.galleryImage_form_displayOrder,
            type: "number",
            rules: "gt:0|lte:255",
            required: true
          },
          {
            id: "imageUri",
            label: this.translations.labels.galleryImage_form_imageUri,
            type: "media",
            mediaType: "image",
            required: true
          },
          {
            id: "localizations",
            type: "localizations",
            header: this.translations.labels.form_localizations,
            languages: this.parentLanguages,
            resetColumns: true,
            elements: [
              {
                id: "title",
                label: this.translations.labels.form_title,
                required: true,
                type: "text"
              }
            ],
          }
        ]
      },
    ];
  }

  get helpLink() {
    return this.translations.pageHelp.galleryImage;
  }

  async created() {
    this.gallery = await this.galleryService.read(this.$route.params.idParent);
    this.events = await this.eventService.list();
    this.afterCreate();
  }
}
